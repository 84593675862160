import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [2];

export const dictionary = {
		"/demo": [3],
		"/gatewayinfo/homey": [4],
		"/home": [~5,[2]],
		"/home/[propertyid]": [~6,[2]],
		"/home/[propertyid]/devices": [~7,[2]],
		"/home/[propertyid]/devices/[deviceid]": [~8,[2]],
		"/home/[propertyid]/settings": [~9,[2]],
		"/home/[propertyid]/settings/properties": [~10,[2]],
		"/home/[propertyid]/settings/properties/new": [12,[2]],
		"/home/[propertyid]/settings/properties/[id]": [~11,[2]],
		"/home/[propertyid]/settings/user": [~13,[2]],
		"/login": [14],
		"/logout": [~15],
		"/onboarding-old": [18],
		"/onboarding-old/onboard": [19],
		"/onboarding-old/step1": [20],
		"/onboarding-old/step2": [21],
		"/onboarding": [16],
		"/onboarding/homey": [17],
		"/password/reset": [22],
		"/password/reset/[token]": [~23],
		"/register": [24],
		"/sentry-example": [25]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';